import React, { useEffect, useRef, useState } from "react";
import useAxiosInstance from "../../../../../../Utilities/Hooks/useAxiosInstance";
import { SIZEGUIRDES } from "../../../../../../Utilities/APIs/APIs";
import CreateButton from "../../../../../Partials/Layouts/CRUDs/CRUDHeader/CreateButton/CreateButton";
import CRUDTable from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../../../Partials/Layouts/CRUDs/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/ViewButton/ViewButton";
import EditButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/EditButton/EditButton";
import DeleteButton from "../../../../../Partials/Layouts/CRUDs/CRUDButtons/DeleteButton/DeleteButton";
import Modal from "../../../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../../../Partials/Layouts/DeleteItem/DeleteItem";
import CreateSizeGuides from "./CreateSizeGuides";
import ViewSizeGuides from "./ViewSizeGuides";
import UpdateSizeGuides from "./UpdateSizeGuides";
import Pagination from "../../../../../Partials/Elements/pagination/Pagination";
import { RiSearchLine } from "react-icons/ri";

function SizeGuides() {
  const axiosInstance = useAxiosInstance();
  const [sizeGuides, setSizeGuides] = useState(null);
  const screenHeaerRef = useRef();
  const [screenHeaderHeight, setScreenHeaderHeight] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [limit, setLimit] = useState(40);
  const [searchInput, setSearchInput] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    let screenHeaderHeight = screenHeaerRef.current.clientHeight;
    setScreenHeaderHeight(screenHeaderHeight);
  }, [screenHeaerRef]);

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);

  const [targetID, setTargetID] = useState(null);

  useEffect(() => {
    async function fetchAndSetColors() {
      const res = await axiosInstance.get(
        `${SIZEGUIRDES}?search=${search}&page=${currentPage}&limit=${limit}`
      );

      setSizeGuides(res?.data?.sizeGuides);
      setTotalItems(res?.data?.totalItems);

      console.log("sizeGuide:", res?.data);
    }
    fetchAndSetColors();
  }, [toggleFetch, axiosInstance, search, currentPage]);

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  const handleSearchInput = (value) => {
    const timeoutId = setTimeout(() => {
      setSearch(value);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  };

  return (
    <>
      <div className="screen_header" ref={screenHeaerRef}>
        <h1 className="screen_heading">Size Guides ({totalItems} in total)</h1>

        <div>
          <input
            value={searchInput}
            onChange={(e) => {
              handleSearchInput(e.target.value);
              setSearchInput(e.target.value);
            }}
            className="input_field"
            placeholder="Search"
            style={{ width: "400px" }}
          />
          <RiSearchLine
            style={{
              width: "30px",
              height: "30px",
              marginLeft: "-50px",
              color: "var(--white-2)",
            }}
          />
        </div>

        <CreateButton
          screenTopicSingular={"size Guides"}
          setShowCreateForm={setShowCreateForm}
        />
      </div>
      <CRUDTable screenHeaderHeight={screenHeaderHeight}>
        <CRUDTableHeader>
          <CRUDth th="Name" />
          <CRUDth th="Actions" />
        </CRUDTableHeader>
        <tbody>
          {sizeGuides?.map((sizeGuird) => (
            <CRUDTableRow key={sizeGuird._id}>
              <ShortTextCell text={sizeGuird.name} />
              <td>
                <div className="action_buttons_wrapper">
                  <div className="action_buttons">
                    <ViewButton
                      setShowViewSection={setShowViewSection}
                      targetID={sizeGuird._id}
                      setTargetID={setTargetID}
                      tooltipName={"View"}
                      tooltipContent={"View"}
                    />

                    <EditButton
                      setShowUpdateForm={setShowUpdateForm}
                      targetID={sizeGuird._id}
                      setTargetID={setTargetID}
                      tooltipName={"Edit"}
                      tooltipContent={"Edit"}
                    />

                    <DeleteButton
                      setShowDeleteSection={setShowDeleteSection}
                      targetID={sizeGuird._id}
                      setTargetID={setTargetID}
                      tooltipName={"Delete"}
                      tooltipContent={"Delete"}
                    />
                  </div>
                </div>
              </td>
            </CRUDTableRow>
          ))}
        </tbody>
        <Pagination
          // items={products}
          totalItems={totalItems}
          limit={limit}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CRUDTable>
      {showCreateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Add Size Guides`}
          setShowModalContent={setShowCreateForm}
        >
          <CreateSizeGuides
            triggerFetch={triggerFetch}
            setShowCreateForm={setShowCreateForm}
          />
        </Modal>
      )}
      {showUpdateForm && (
        <Modal
          extraClass={"small"}
          modalHeading={`Update Size Guide`}
          setShowModalContent={setShowUpdateForm}
        >
          <UpdateSizeGuides
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowUpdateForm={setShowUpdateForm}
          />
        </Modal>
      )}
      {showViewSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`View Size Guides`}
          setShowModalContent={setShowViewSection}
        >
          <ViewSizeGuides targetID={targetID} />
        </Modal>
      )}
      {showDeleteSection && (
        <Modal
          extraClass={"small"}
          modalHeading={`Delete Color`}
          setShowModalContent={setShowDeleteSection}
        >
          <DeleteItem
            api={SIZEGUIRDES}
            triggerFetch={triggerFetch}
            targetID={targetID}
            setShowDeleteItem={setShowDeleteSection}
          />
        </Modal>
      )}
    </>
  );
}

export default SizeGuides;
