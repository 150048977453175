import './FormSubmitButton.css'

function FormSubmitButton({ text, disabled }) {
  return (
    <div className="btn_wrapper">
      <button type='submit' className='submit w_100' disabled={disabled ? disabled : false}>{text}</button>
    </div>
  )
}

export default FormSubmitButton